<template>
    <div class="content">
        <el-carousel :height="swiperHeight+'px'"
                     arrow="never"
                     :indicator-position="topSwiperList.length > 1 ? '' : 'none'">
            <el-carousel-item v-for="(item, index) in topSwiperList" :key="index">
                <img class="topSwiper" :src="item.img" alt="">
                <div class="topSwiper-wrap" v-if="index === 0">
                    <div class="wrap-con">
                        <div class="title">提升个人学历 助你升职加薪</div>
                        <div class="desc">专科>本科>研究生</div>
                        <div class="btnBox">
                            <div class="detailsBtn" @click="topBannerBtn">
                                <span>了解详情</span>
                                <img src="../../assets/images/eduPersonal/icon-play.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="connectBox">
            <div class="contentBox-con">
                <div class="contentBoxCon-left">
                    <div class="name">培考通</div>
                    <div class="point">·</div>
                    <div class="desc">学历提升</div>
                </div>
                <div class="contentBoxCon-right" @click="topBannerBtn">
                    <v-icon class="icon" name="icon-penBook" size="26"></v-icon>
                    <div class="text">预约咨询</div>
                </div>
            </div>
        </div>
        <div class="box followSwiperBox">
            <div class="box-con">
                <div class="title">公告公示</div>
                <div class="followSwiperBox-main">
                    <div class="main-left">
                        <el-carousel
                            :height=" littleSwiperHeight + 'px'"
                            arrow="never"
                            @change="swiperChange"
                            indicator-position="none">
                            <el-carousel-item v-for="(item, index) in littleSwiperData" :key="index">
                                <img :src="item.img" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="main-right">
                        <div :class="['main-row', index === swiperIndex ? 'main-row-active' : '']"
                             @click="littleSwiperClick(item.url)"
                             v-for="(item, index) in littleSwiperData" :key="index">
                            <div>{{ item.title }}</div>
                            <v-icon v-show="swiperIndex === index" class="icon" name="icon-arrowRA" size="14"></v-icon>
                            <v-icon v-show="swiperIndex !== index" class="icon" name="icon-arrowR" size="14"></v-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box collegeBox">
            <div class="box-con">
                <div class="title">合作院校</div>
                <div class="collegeBox-main">
                    <div class="collegeBox-main--row">
                        <div class="imgBox">
                            <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/collegeBg1.png" alt="">
                        </div>
                        <div class="textBox">
                            <div class="textBox-row">
                                <div class="collegeIcon"><img src="../../assets/images/eduPersonal/college1.png" alt=""></div>
                                <div class="collegeName">上海开放大学</div>
                            </div>
                            <div class="collegeDesc">
                                上海开放大学是由上海市人民政府举办，教育部批准成立，上海市教育委员会管理的新型高等学校。学校前身为上海电视大学，创办于1960年。
                                学校以现代信息技术为支撑，以远程开放教育为主要形式，主要面向成人开展专科、专升本科教育。学校目前共开设51个专业，注册在读学生约8万名。自学校开办以来共计培养各类本、专科毕业生 57万余名。
                            </div>
                            <div class="proTitle">校企定制合作项目</div>
                            <div class="textBox-row row-mt30">
                                <div class="typeName">学历：本科</div>
                                <div class="arrowBox">
                                    <div class="text">专业</div>
                                    <img class="longArrow" src="../../assets/images/eduPersonal/longArrow.png" alt="">
                                </div>
                                <div class="likeBtn">家政学</div>
                            </div>
                            <div class="textBox-row row-mt30">
                                <div class="typeName">学历：大专</div>
                                <div class="arrowBox">
                                    <div class="text">专业</div>
                                    <img class="longArrow" src="../../assets/images/eduPersonal/longArrow.png" alt="">
                                </div>
                                <div class="likeBtn">家政服务与管理</div>
                                <div class="likeBtn">养老服务与管理</div>
                            </div>
                        </div>
                    </div>
                    <div class="collegeBox-main--row">
                        <div class="textBox">
                            <div class="textBox-row">
                                <div class="collegeIcon"><img src="../../assets/images/eduPersonal/college2.png" alt=""></div>
                                <div class="collegeName">宁波卫生职业技术学院</div>
                            </div>
                            <div class="collegeDesc">
                                宁波卫生职业技术学院位于浙江省宁波市，是一所宁波市政府直属的全日制国有公办普通高等学校。学校前身为创办于1925年的宁波华美高级护士职业学校。
                                学校现有全日制在校生6300余人；校区占地面积23.91万平方米，建筑面积12.59万平方米，紧密型校企合作基地占地面积92.63万平方米，建筑面积149.56万平方米；固定资产总值4.9亿元，教学仪器设备总值7649.33万元（生均1.26万元），图书总量40.61万册（生均64.40册）；教职工398人，专任教师262人，其中具有高级职称教师占33%。
                            </div>
                            <div class="proTitle">校企定制合作项目</div>
                            <div class="textBox-row row-mt30">
                                <div class="typeName">学历：大专</div>
                                <div class="arrowBox">
                                    <div class="text">专业</div>
                                    <img class="longArrow" src="../../assets/images/eduPersonal/longArrow.png" alt="">
                                </div>
                                <div class="likeBtn">家政服务与管理</div>
                            </div>
                        </div>
                        <div class="imgBox">
                            <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/collegeBg2.png" alt="">
                        </div>
                    </div>
                    <div class="collegeBox-main--row">
                        <div class="imgBox">
                            <img src="https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/collegeBg3.png" alt="">
                        </div>
                        <div class="textBox">
                            <div class="textBox-row">
                                <div class="collegeIcon"><img src="../../assets/images/eduPersonal/college3.png" alt=""></div>
                                <div class="collegeName">云南临沧卫校</div>
                            </div>
                            <div class="collegeDesc">
                                50多年来为临沧市及全省培养了万余名实用型卫生技术人才，为人民的健康、经济社会的发展做出了应有的贡献。
                                学校新、老校区占地152.6亩，校舍总建筑面积为82900.82平方米。其中附属医院建筑面积19000平方米。学校现有教职工107人，专任教师72人，本科及以上学历达100%，有省级学科带头人2人，市级学科带头人5人，高级讲师24人，讲师23人。目前各级各类在校生总数达6800余人，其中，全日制中专5173人，五年制大专280人（已毕业120余人），乡村医生中医中专化学历教育288人，成人学员1202人。
                            </div>
                            <div class="proTitle">校企定制合作项目</div>
                            <div class="textBox-row row-mt30">
                                <div class="typeName">学历：中专</div>
                                <div class="arrowBox">
                                    <div class="text">专业</div>
                                    <img class="longArrow" src="../../assets/images/eduPersonal/longArrow.png" alt="">
                                </div>
                                <div class="likeBtn">护理</div>
                                <div class="likeBtn">养老服务与管理</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <!-- 预约咨询弹窗 -->
        <appointmentCon @closeDialog='clsoeDialog' :appointmentTitle='appointmentTitle' :appointmentForm = 'appointmentForm' :appointmentVisiable='appointmentVisiable'></appointmentCon>
    
    </div>
</template>

<script>
import appointmentCon from '../../components/appointmentCon.vue';

export default {
    name: "educationPersonal",
    components:{
        appointmentCon
    },
    data () {
        return {
            // 预约咨询
            appointmentVisiable:false,
            appointmentForm:{},
            appointmentTitle:"",
            swiperHeight: 680,
            swiperIndex: 0,
            topSwiperList: [
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/eduSwiper1.png',
                    url: ''
                }
            ],
            littleSwiperHeight: 218,
            littleSwiperData: [
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/littleSwiper1.png',
                    url: 'https://baijiahao.baidu.com/s?id=1719989120607009946&wfr=spider&for=pc',
                    title: '国成教协会学分银行研究专委会成立'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/littleSwiper2.png',
                    url: 'https://baijiahao.baidu.com/s?id=1718723468363897545&wfr=spider&for=pc',
                    title: '上海终身教育学分银行接入“一网通办”平台'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/littleSwiper3.png',
                    url: 'https://mp.weixin.qq.com/s/N9U2bJVBBRqwXMNXMasPyg',
                    title: '职业本科，来了！为什么办？谁来办？怎么办?'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/littleSwiper4.png',
                    url: 'https://mp.weixin.qq.com/s/Soo_DZ9_KwSDKLQpspzZ1A',
                    title: '北京经开区校企合作人才联合培养基地再扩容'
                },
                {
                    img: 'https://peikaotong-oss.oss-cn-hangzhou.aliyuncs.com/upload/imgs/littleSwiper5.png',
                    url: 'https://m.thepaper.cn/baijiahao_9550769',
                    title: '“亦城工匠”品牌怎么打造，解读来了！'
                },
            ],
        }
    },
    mounted () {
        let fn = this.init
        fn()
        window.onresize = function () {
            fn()
        }
    },
    methods: {
        init () {
            let width = window.innerWidth
            this.swiperHeight = width < 1560 ? 560 : 680
            this.littleSwiperHeight = width < 1560 ? 218 : 270
        },
        swiperChange (index) {
            this.swiperIndex = index
        },
        littleSwiperClick (url) {
            if (url) {
                window.open(url, 'blank')
            }
        },
         // 预约咨询
        topBannerBtn(){
            this.appointmentForm = {};
            this.appointmentTitle = '学历提升预约咨询';
            this.appointmentForm ={
                contact:'',
                phone:'',
                education:'',
                major:"",
                type:"person"
            }
            this.appointmentVisiable = true;
        },
        // 关闭弹窗
        clsoeDialog(){
            this.appointmentVisiable = false
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep {
    .main-row {
        .icon {
            width: 9px;
            img {
                width: 9px !important;
            }
        }
    }
}

@media screen and (max-width: 1560px) {
    .content {
        width: 100%;
        min-width: 1200px;
        background: #fafafa;
        box-sizing: border-box;

        .topSwiper {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
        }

        .topSwiper-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);

            .wrap-con {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .title {
                    font-size: 42px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .desc {
                    margin: 43px auto 0;
                    text-align: center;
                    font-size: 20px;
                    color: #FFFFFF;
                }
                .btnBox {
                    margin: 73px auto 0;
                    width: 156px;
                    height: 48px;
                    border-radius: 2px;
                    overflow: hidden;
                    cursor: pointer;
                }
                .detailsBtn {
                    width: 152px;
                    height: 44px;
                    background: linear-gradient(139deg, rgba(193, 246, 255, 0.2) 0%, rgba(121, 144, 255, 0.21) 100%);
                    border: 2px solid;
                    border-image: -webkit-linear-gradient(#ddd,#000) 1 10 1;
                    border-image: -moz-linear-gradient(#ddd,#000) 1 10 1;
                    border-image: linear-gradient(to right , #C1F6FF, #7990FF) 1 10 1;
                    font-size: 20px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 21px;
                        height: 21px;
                        opacity: 0.6;
                        margin-left: 10px;
                    }
                }
            }
        }

        .connectBox {
            margin: 20px 0;
            width: 100%;
            height: 60px;
            background-image: url("../../assets/images/eduPersonal/connect-bg@2x.png");
            background-size: 100% 100%;

            .contentBox-con {
                height: 100%;
                width: 1000px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: auto;

                .contentBoxCon-left {
                    color: #333333;
                    font-size: 19px;
                    display: flex;

                    .name {
                        //font-size: 23px;
                        font-weight: bold;
                    }
                    .point {
                        //font-size: 23px;
                        margin-left: 5px;
                    }
                    .desc {
                        margin-left: 5px;
                    }
                }
                .contentBoxCon-right {
                    cursor: pointer;
                    width: 120px;
                    height: 36px;
                    border-radius: 23px;
                    border: 1px solid #333333;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .text {
                        margin-left: 5px;
                    }
                }
            }
        }
        .box {
            width: 100%;

            .box-con {
                width: 1000px;
                margin: auto;
                padding: 30px 0;
                color: #333333;

                .title {
                    height: 22px;
                    border-left: 3px solid #EA5A41;
                    padding-left: 10px;
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 22px;
                }
                .followSwiperBox-main {
                    margin-top: 30px;
                    display: flex;

                    .main-left {
                        width: 350px;
                        height: 218px;
                    }
                    .main-right {
                        margin-left: 20px;

                        .main-row {
                            width: 630px;
                            height: 35px;
                            border-bottom: 1px dashed #eeeeee;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 14px;
                            color: #333333;
                            padding-left: 5px;
                            cursor: pointer;
                        }
                        .main-row:hover {
                            color: #EA5A41;
                        }
                        .main-row-active {
                            border-bottom: 1px dashed #EA5A41;
                            background: #FFF6F5;
                            font-size: 16px;
                            font-weight: bold;
                            color: #EA5A41;
                        }
                    }
                }

                .collegeBox-main {

                    .collegeBox-main--row {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 38px;

                        .imgBox {
                            width: 414px;
                            height: 300px;
                        }
                        .textBox {
                            width: 543px;
                            //height: 368px;

                            .textBox-row {
                                display: flex;
                                align-items: center;

                                .collegeIcon {
                                    width: 40px;
                                    height: 40px;
                                }
                                .collegeName {
                                    font-size: 20px;
                                    font-weight: bold;
                                    margin-left: 10px;
                                }

                                .typeName {
                                    font-size: 12px;
                                }
                                .arrowBox {
                                    margin-left: 20px;
                                    width: 108px;
                                    margin-top: -18px;

                                    .text {
                                        width: 100%;
                                        text-align: center;
                                        font-size: 12px;
                                        color: #EA5A41;
                                        position: relative;
                                        top: 4px;
                                    }
                                    .longArrow {
                                        width: 100%;
                                        height: 10px;
                                    }
                                }
                                .likeBtn {
                                    font-size: 14px;
                                    padding: 6px 16px;
                                    background: #EEEEEE;
                                    border-radius: 20px;
                                    margin-left: 30px;
                                }
                            }
                            .row-mt30 {
                                margin-top: 30px;
                            }
                            .collegeDesc {
                                margin-top: 20px;
                                font-size: 13px;
                                color: #666666;
                            }
                            .proTitle {
                                margin-top: 33px;
                                font-size: 14px;
                                font-weight: bold;
                            }

                        }
                    }
                }
            }
        }
        .followSwiperBox {
            background: #FFFFFF;
        }
        .collegeBox {
            padding-bottom: 96px;
        }
    }
}

@media screen and (min-width: 1560px) {
    .content {
        width: 100%;
        min-width: 1200px;
        background: #fafafa;
        box-sizing: border-box;

        .topSwiper {
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
        }

        .topSwiper-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);

            .wrap-con {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .title {
                    font-size: 50px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .desc {
                    margin: 43px auto 0;
                    text-align: center;
                    font-size: 24px;
                    color: #FFFFFF;
                }
                .btnBox {
                    margin: 73px auto 0;
                    width: 176px;
                    height: 58px;
                    border-radius: 2px;
                    overflow: hidden;
                    cursor: pointer;
                }
                .detailsBtn {
                    width: 172px;
                    height: 54px;
                    background: linear-gradient(139deg, rgba(193, 246, 255, 0.2) 0%, rgba(121, 144, 255, 0.21) 100%);
                    border-radius: 4px;
                    border: 2px solid;
                    border-image: -webkit-linear-gradient(#ddd,#000) 1 10 1;
                    border-image: -moz-linear-gradient(#ddd,#000) 1 10 1;
                    border-image: linear-gradient(to right , #C1F6FF, #7990FF) 1 10 1;
                    font-size: 24px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 21px;
                        height: 21px;
                        opacity: 0.6;
                        margin-left: 10px;
                    }
                }
            }
        }
        .connectBox {
            margin: 20px 0;
            width: 100%;
            height: 76px;
            background-image: url("../../assets/images/eduPersonal/connect-bg@2x.png");
            background-size: 100% 100%;

            .contentBox-con {
                height: 100%;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: auto;

                .contentBoxCon-left {
                    color: #333333;
                    font-size: 23px;
                    display: flex;

                    .name {
                        //font-size: 23px;
                        font-weight: bold;
                    }
                    .point {
                        //font-size: 23px;
                        margin-left: 5px;
                    }
                    .desc {
                        margin-left: 5px;
                    }
                }
                .contentBoxCon-right {
                    cursor: pointer;
                    width: 160px;
                    height: 46px;
                    border-radius: 23px;
                    border: 1px solid #333333;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .text {
                        margin-left: 9px;
                    }
                }
            }
        }
        .box {
            width: 100%;

            .box-con {
                width: 1200px;
                margin: auto;
                padding: 40px 0;
                color: #333333;

                .title {
                    height: 22px;
                    border-left: 4px solid #EA5A41;
                    padding-left: 10px;
                    font-size: 26px;
                    font-weight: bold;
                    line-height: 22px;
                }
                .followSwiperBox-main {
                    margin-top: 40px;
                    display: flex;

                    .main-left {
                        width: 480px;
                        height: 270px;
                    }
                    .main-right {
                        margin-left: 20px;

                        .main-row {
                            width: 700px;
                            height: 44px;
                            border-bottom: 1px dashed #eeeeee;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 16px;
                            color: #333333;
                            padding-left: 5px;
                            cursor: pointer;
                        }
                        .main-row:hover {
                            color: #EA5A41;
                        }
                        .main-row-active {
                            border-bottom: 1px dashed #EA5A41;
                            background: #FFF6F5;
                            font-size: 18px;
                            font-weight: bold;
                            color: #EA5A41;
                        }
                    }
                }

                .collegeBox-main {

                    .collegeBox-main--row {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 48px;

                        .imgBox {
                            width: 514px;
                            height: 368px;
                        }
                        .textBox {
                            width: 633px;
                            //height: 368px;

                            .textBox-row {
                                display: flex;
                                align-items: center;

                                .collegeIcon {
                                    width: 60px;
                                    height: 60px;
                                }
                                .collegeName {
                                    font-size: 24px;
                                    font-weight: bold;
                                    margin-left: 15px;
                                }

                                .typeName {
                                    font-size: 14px;
                                }
                                .arrowBox {
                                    margin-left: 30px;
                                    width: 108px;
                                    margin-top: -18px;

                                    .text {
                                        width: 100%;
                                        text-align: center;
                                        font-size: 12px;
                                        color: #EA5A41;
                                        position: relative;
                                        top: 4px;
                                    }
                                    .longArrow {
                                        width: 100%;
                                        height: 10px;
                                    }
                                }
                                .likeBtn {
                                    padding: 9px 19px;
                                    background: #EEEEEE;
                                    border-radius: 20px;
                                    margin-left: 30px;
                                }
                            }
                            .row-mt30 {
                                margin-top: 30px;
                            }
                            .collegeDesc {
                                margin-top: 20px;
                                font-size: 14px;
                                color: #666666;
                            }
                            .proTitle {
                                margin-top: 43px;
                                font-size: 16px;
                                font-weight: bold;
                            }

                        }
                    }
                }
            }
        }
        .followSwiperBox {
            background: #FFFFFF;
        }
        .collegeBox {
            padding-bottom: 96px;
        }
    }
}
</style>